import React, { Suspense, useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import ScrollToTop from "./components/Layout/ScrollToTopButton";

const Home = React.lazy(() => import("./pages/Home.js"));
const Contact = React.lazy(() => import("./pages/Contact.js"));
const Development = React.lazy(() => import("./pages/Development.js"));
const Business = React.lazy(() => import("./pages/Business.js"));
const Outsourcing = React.lazy(() => import("./pages/Outsourcing.js"));
const Recruitement = React.lazy(() => import("./pages/Recruitement.js"));
const Setting = React.lazy(() => import("./pages/Setting.js"));

function App() {
  return (
    <Suspense fallback={""}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/software-development" element={<Development />} />
          <Route path="/business-consulting" element={<Business />} />
          <Route path="/scalability" element={<Outsourcing />} />
          <Route path="/recruitment" element={<Recruitement />} />
          <Route path="/team-setting" element={<Setting />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
