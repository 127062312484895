import React, { createContext, useContext, useState } from "react";

// Tworzenie kontekstu
export const LanguageContext = createContext();

// Wartość początkowa języka
const initialLanguage = localStorage.getItem("language") || "EN"; // Domyślnie ustawiamy język na 'en'

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(initialLanguage);

  // Funkcja do ustawiania języka i zapisywania go w pamięci podręcznej
  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Hook do korzystania z kontekstu
export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
